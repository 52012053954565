<template>
    <el-dialog
        :title="mode == 'add' ? '新增问题' : '编辑问题'"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false">
        <el-form :model="form" :rules="rules" ref="form" label-width="80px" class="demo-ruleForm">
            <el-form-item label="问题" prop="question">
                <el-input v-model="form.question" placeholder="请输入问题"></el-input>
            </el-form-item>
            <el-form-item label="答案" prop="answer">
                <el-input v-model="form.answer" placeholder="请输入答案"></el-input>
            </el-form-item>
            <el-form-item label="排序" prop="seq">
                <el-input-number v-model="form.seq" :min="1" size="medium"></el-input-number>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" size="medium" @click="save">保存</el-button>
            <el-button size="medium" @click="closeDialog">取 消</el-button>
        </span>
    </el-dialog>
</template>
<script>
export default {
    props:{
        vkey: {
            type: String
        },
        toolId: {
            type: String
        }
    },
    data() {
        return {
            dialogVisible: false,
            mode: 'add',
            form: {
                answer: "",
                question: "",
                seq: 1
            },
            rules: {
                question: [
                    { required: true, message: '请输入问题', trigger: 'blur' }
                ],
                answer: [
                    { required: true, message: '请输入答案', trigger: 'blur' }
                ],
                seq: [
                    { required: true, message: '请选择排序序号', trigger: 'change' }
                ]
            }
        };
    },
    methods: {
        show(type, row) {
            this.dialogVisible = true;
            this.mode = type;
            if(type == 'add') {
                this.form = {
                    answer: "",
                    question: "",
                    seq: 1,
                    toolId: this.toolId
                }
            } else {
               this.getDetails(row.id)
            }
            this.$refs['form'] ? this.$refs['form'].resetFields() : '';
        },
        getDetails(id) {
            this.$store.dispatch('manage/public', {
                query: id,
                requestInterfaceName: 'getQuestion'
            }).then(res=>{
                if(res.code == 0) {
                    this.form = {
                        answer: res.data.answer,
                        question: res.data.question,
                        seq: res.data.seq,
                        id: res.data.id
                    }
                }
            })
        },
        // 保存
        save() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.$store.dispatch(`manage/${this.mode == 'add' ? 'add' : 'edit'}`, {
                        vkey: this.vkey,
                        query: this.form,
                        requestInterfaceName: this.mode == 'add' ? 'addQuestion' : 'editQuestion',
                        listInterfaceName: 'getQuestionList'
                    }).then(res=>{
                        if(res.code == 0) {
                            this.$message.success(this.mode == 'add' ? '添加成功' : '修改成功');
                        } else {
                            this.$message.error(res.message);
                        }
                        this.closeDialog();
                    })
                }
            });
        },
        closeDialog() {
            this.dialogVisible = false;
            this.$refs['form'].resetFields();
        }
    }
};
</script>
<style lang="less" scoped>
.demo-ruleForm {
    /deep/ .el-upload {
        width: 120px;
        height: 120px;
        line-height: 124px;
    }
}
</style>