<template>
    <div style="padding: 20px;">
        <el-page-header @back="goBack" content="工具管理 > 问答管理" class="version-page-header"></el-page-header>
       <SearchCommon :formInline="formInline" :formList="formList" @onSearch="onSearch" @onResetBtn="onResetBtn"></SearchCommon>
       <el-button type="primary" icon="el-icon-plus" size="medium" style="margin: 0 0 20px 0;" @click="handleQuestionEvent('add')">添加</el-button>
        <template>
            <TableCommon isMargin="20px" :tableColumn="tableColumn" :tableData="tableData">
                <el-table-column
                label="操作"
                align="center"
                width="130">
                    <template slot-scope="scope">
                        <el-button type="text" icon="el-icon-edit" size="medium" @click="handleQuestionEvent('edit', scope.row)">编辑</el-button>
                        <el-button type="text" icon="el-icon-delete" size="medium" @click="handleQuestionEvent('remove', scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </TableCommon>
        </template>
        <el-pagination
        class="pagebox"
        @current-change="handleCurrentChange"
        :current-page="formInline.pageNum"
        :page-size="formInline.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total">
        </el-pagination>
        
       <addQuestion ref="addQuestionDialog" :vkey="vkey" :toolId="$route.params.id"></addQuestion>
    </div>
</template>
<script>
import SearchCommon from '../../../components/SearchCommon.vue';
import TableCommon from '../../../components/TableCommon.vue';
import addQuestion from './components/addQuestion.vue';
export default {
    data() {
        return {
            vkey: 'questionPage',
            formInline: {
                question: '',
                pageNum: 1,
                pageSize: 10
            },
            formList: [
                { label: '问题', model: 'question', placeholder: '请输入问题', type: 'input' }
            ],
            tableColumn: [
                { label: '序号', type: 'index', align: 'center', width: '60' },
                { label: '问题', prop: 'question', align: 'center' },
                { label: '答案', prop: 'answer', align: 'center' },
                { label: '排序', prop: 'seq', align: 'center' }
            ]
        }
    },
    components: {
        SearchCommon,
        TableCommon,
        addQuestion
    },
    computed: {
        tableData(){
            return this.$store.state.manage.views[this.vkey].list || [];
        },
        total() {
            return this.$store.state.manage.views[this.vkey].paging.total;
        }
    },
    created(){
        this.onLoadList()
    },
    methods: {
        // 列表
        onLoadList(){
            this.formInline.toolId = this.$route.params.id;
            this.$store.dispatch('manage/list', {
                vkey: this.vkey,
                reset: true,
                query: this.formInline,
                interfaceName: 'getQuestionList'
            })
        },
        // 搜索
        onSearch() {
            this.formInline.pageNum = 1;
            this.onLoadList();
        },
        // 清空
        onResetBtn() {
            this.formInline = {
                question: '',
                pageNum: 1,
                pageSize: 10
            }
            this.onLoadList()
        },
        // 返回
        goBack() {
            this.$router.go(-1);
        },
        //按钮操作
        handleQuestionEvent(type, row) {
            switch(type) {
                case 'add': 
                    this.$nextTick(()=>{
                        this.$refs.addQuestionDialog.show(type)
                    })
                break;

                case 'edit':
                    this.$nextTick(()=>{
                        this.$refs.addQuestionDialog.show(type, row)
                    })
                break;

                case 'remove':
                    this.$confirm('确定是否要删除当前选择的数据?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.$store.dispatch('manage/remove', {
                            vkey: this.vkey,
                            query: row.id,
                            requestInterfaceName: 'removeQuestion',
                            listInterfaceName: 'getQuestionList'
                        }).then(res=>{
                            if(res.code == 0) {
                                this.$message.success('删除成功');
                                this.formInline.pageNum = 1;
                            } else {
                                this.$message.error(res.message);
                            }
                        })
                    }).catch(() => {});
                break;
            }
        },
        // 分页
        handleCurrentChange(val){
            this.formInline.pageNum = val;
            this.onLoadList();
        }
    }
  }
</script>
<style lang="less" scoped>
.version-page-header {
    margin: 0 0 20px 0;
    /deep/ .el-page-header__content {
        font-size: 17px;
    }
}
</style>